import BoxSection from "components/program/box-section"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import SEO from "components/seo"
import Top from "components/program/top"
import { graphql } from "gatsby"
import useTranslations from "components/use-translations"

export default function CekilisSitesi({ data }) {
  const {
    genel: { dahaFazlaBilgiMiGerekli, ozellikler, ekranGoruntuleri },
    cekilisSite: {
      seoTitle,
      seoDescription,
      baslik,
      altBaslik,
      altBaslikAlti,
      kullaniciMesaji,
      kullaniciAdi,
      indirButonuYazi,
      butonAltiYazi,
      bolumler,
    },
    cekilis: {
      hizliCekilis,
      afilliCekilis,
      cark,
      rastgeleSayi,
      zar,
      yaziTura,
      instagramAraci,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/cekilis-sitesi"
        datePublished="2017-02-09T22:11:53+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container>
        <Top
          headerText={baslik}
          headerBottomText={altBaslik}
          useFreeText={altBaslikAlti}
          quoteText={kullaniciMesaji}
          quoteAuthor={kullaniciAdi}
          buttonText={indirButonuYazi}
          buttonLink="/cekilis"
          buttonBottomText={butonAltiYazi}
          buttonColor="#0085b5"
          containerStyle={{ paddingBottom: 0 }}
        >
          <Img fluid={data.cekilisBilgisayar.childImageSharp.fluid} />
        </Top>
      </Container>
      <div className="my-5"></div>
      {/*  1. En Hızlı Çekiliş Sitesi*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[0].baslik}
        descriptionText={bolumler[0].aciklama}
        listItems={bolumler[0].maddeler}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.hiz.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 2. Çekilişinizi Kaydedin*/}
      <BoxSection
        childPositionLeft={true}
        headerText={bolumler[1].baslik}
        descriptionText={bolumler[1].aciklama}
        listItems={bolumler[1].maddeler}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.kaydet.childImageSharp.fluid} />
        </div>
      </BoxSection>

      {/* 3.Farklı araçlar kullanın*/}
      <BoxSection
        bgLight={true}
        headerText={bolumler[2].baslik}
        descriptionText={bolumler[2].aciklama}
        listItems={[
          hizliCekilis,
          afilliCekilis,
          cark,
          rastgeleSayi,
          zar,
          yaziTura,
          instagramAraci,
          ...bolumler[2].maddeler,
        ]}
      >
        <div className="mx-auto" style={{ maxWidth: 100 }}>
          <Img fluid={data.gorsel.childImageSharp.fluid} />
        </div>
      </BoxSection>

      <BoxSection containerStyle={{ paddingTop: 0 }}>
        <h5 className="text-center">{dahaFazlaBilgiMiGerekli}</h5>

        <ul className="list-unstyled text-center m-0" style={{ fontSize: 16 }}>
          <li className="mb-0">
            <LocalizedLink to="/cekilis-sitesi/ozellikler">
              {ozellikler}
            </LocalizedLink>
          </li>
          <li className="mb-0">
            <LocalizedLink to="/cekilis-sitesi/ekran-goruntuleri">
              {ekranGoruntuleri}
            </LocalizedLink>
          </li>
        </ul>
      </BoxSection>
    </Layout>
  )
}

export const query = graphql`
  query {
    cekilisBilgisayar: file(
      relativePath: { eq: "cekilissite/cekilisslaptopta.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          srcWebp
        }
      }
    }
    hiz: file(relativePath: { eq: "cekilis/hiz.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          srcWebp
        }
      }
    }
    kaydet: file(relativePath: { eq: "cekilis/kaydet.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          srcWebp
        }
      }
    }
    gorsel: file(relativePath: { eq: "cekilis/gorsel.png" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          srcWebp
        }
      }
    }
  }
`

CekilisSitesi.propTypes = {
  data: PropTypes.object,
}
